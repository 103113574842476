<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <span class="uk-text-lead uk-text-bold">
                    Vendor List
                </span>
            </div>
            <div class="uk-flex uk-flex-right">
                <button class="uk-close-large" type="button" uk-close @click="$router.push(`/admin/community/ewa`)"></button>
            </div>
        </div>
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'name')" type="text" placeholder="Search Vendor Name">
                </div>
            </div>
            <div v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-flex uk-flex-right">
                <button class="uk-button uk-button-small uk-button-primary uk-border-rounded" type="button" @click="showCreateModal=true">
                    <span>Set Up Vendor</span>
                </button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-right">
                            <th class="uk-text-center">Vendor Name</th>
                            <th class="uk-text-center uk-text-nowrap">URL</th>
                            <th class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="3"/>
                    <tbody v-else-if="page_data.length>0">
                        <tr v-for="(data, i) in page_data" :key="i" class="uk-text-left">
                            <td class="uk-text-center">{{preview(data.name)}}</td>
                            <td class="uk-text-center">{{preview(data.url)}}</td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button">Actions</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <template v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-flex uk-flex-right">
                                            <li><a @click="showEditVendor(data)" class="uk-dropdown-close">Edit</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li><a @click="showDeleteVendor(data)" class="uk-dropdown-close">Delete</a></li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="3"/>
                </table>
            </div>
        </div>
        <CreateModal v-if="showCreateModal" @hideModal="hideModal" @save="createVendor"/>
        <EditModal v-if="showEditModal" :data="component_data" @hideModal="hideModal" @save="editVendor"/>
        <DeleteModal v-if="showDeleteModal" :data="component_data" @hideModal="hideModal" @delete="deleteVendor"/>
    </div>
</template>

<script>
// import formatter from "@/utils/formatter";
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import { PREFIX_IMAGE } from '@/utils/constant';
import moment from 'moment';
moment.locale('id');
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
import CreateModal from './create';
import EditModal from './edit';
import DeleteModal from './delete';

export default {
    data() {
        return {
            user_cred: getUserLogin(),
            moment: moment,
            images: PREFIX_IMAGE,
            meta: {
                name: '',
            },
            page_data: [],
            isLoading: true,
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false,
            component_data: null
        };
    },
    components: {
        EmptyTable,
        LoadingTable,
        CreateModal,
        EditModal,
        DeleteModal
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    beforeMount() {
        if(window.UIkit.modal('.mainModal')) window.UIkit.modal('.mainModal').$destroy(true);
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getEWAVendor: 'community/getEWAVendor',
            createEWAVendor: 'community/createEWAVendor',
            editEWAVendor: 'community/editEWAVendor',
            deleteEWAVendor: 'community/deleteEWAVendor'
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getEWAVendor(this.meta);
            this.isLoading = false;
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        hideModal(isRefresh){
            if (isRefresh) this.getInitPageData();
            
            this.showCreateModal = false;
            this.showEditModal = false;
            this.showDeleteModal = false;
            this.component_data = null;
        },
        showEditVendor(data) {
            this.component_data = data;
            this.showEditModal = true;
        },
        showDeleteVendor(data) {
            this.component_data = data;
            this.showDeleteModal = true;
        },
        async createVendor(data) {
            try {
                this.isLoading = true;
                const response = await this.createEWAVendor(data);
                this.isLoading = false;

                if (response) {
                    notificationSuccess('Data Created!');
                    await this.getInitPageData();
                    this.showCreateModal = false;
                } else {
                    notificationDanger('Failed to create!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async editVendor(data) {
            try {
                this.isLoading = true;
                const response = await this.editEWAVendor(data);
                this.isLoading = false;

                if (response) {
                    notificationSuccess('Data Edited!');
                    await this.getInitPageData();
                    this.showEditModal = false;
                } else {
                    notificationDanger('Failed to edit!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async deleteVendor(data) {
            try {
                this.isLoading = true;
                const response = await this.deleteEWAVendor(data);
                this.isLoading = false;

                if (response) {
                    notificationSuccess('Data Deleted!');
                    await this.getInitPageData();
                    this.showDeleteModal = false;
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    },
};
</script>

<style scoped>

</style>
