<template>
    <section>
      <div id="mainModal" uk-modal esc-close="false" bg-close="false">
        <div class="uk-modal-dialog">
          <button
            class="uk-modal-close-default"
            type="button"
            uk-close
            @click="hideModal(false)"
          ></button>
          <div class="uk-modal-header">
            <h2 class="uk-modal-title">Delete EWA Vendor</h2>
          </div>
          <div class="uk-modal-body">
            <p>Are you sure to delete this data?</p>
            <p>
              <span>ID: {{ data._id }}</span><br>
              <span>Name: {{ data.name }}</span><br>
              <span>URL: {{ data.url }}</span>
            </p>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <section v-if="isLoading">
              <button class="uk-button uk-button-primary" type="button" disabled>
                <div uk-spinner></div>
              </button>
            </section>
            <section v-else>
              <button
                class="uk-button uk-button-danger uk-border-rounded"
                type="button"
                @click="save"
              >
                Delete
              </button>
            </section>
          </div>
        </div>
      </div>
    </section>
</template>
  
<script>
import { PREFIX_IMAGE } from "@/utils/constant";

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
        };
    },
    async mounted() {
        window.UIkit.modal("#mainModal").show();
    },
    methods: {
        hideModal(isRefresh) {
            window.UIkit.modal("#mainModal").$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        async save() {
            window.UIkit.modal("#mainModal").$destroy(true);
            this.$emit("delete", this.data._id);
        }
    },
};
</script>
